<template>
  <div class="repair">
    <p v-if="rows && rows.meta" class="pagination__text">
      {{ rows.meta.from }}-{{ rows.meta.to }} из
      {{ rows.meta.total }}
    </p>
    <b-pagination
        v-model="currentPage"
        v-if="rows && rows.meta"
        :total-rows="rows.meta.total"
        :per-page="perPage"
        aria-controls="my-table"
        :hide-goto-end-buttons="true"
        :limit="1"
        class="pagination"
        @input="changePage()"
    >
      <template #prev-text>
        <img
            class="pagination__btn-icon pagination__btn-icon_prev"
            src="@/assets/png/database-icon-active.png"
            alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>

    <div  class="row stats">
      <div class="col">
        <RegularBlock  />
      </div>
      <div class="col">
        <StatisticBlock  />
      </div>
    </div>

    <div class="table">
      <b-table
          v-if="rows"
          id="my-table"
          class="table__box"
          :borderless="true"
          :items="rows.data"
          :fields="fields"
          small
      >
        <template #cell(character)="data">
          <div>
            <span class="product-category">{{ data.item.character }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <b-form-select
              v-model="data.item.status"
              style="text-align: center; width: 110px"
              :class="statuses[data.item.status]"
              :options="listStatus"
              @change="changeStatus(data.item)"
              text-field="name"
              value-field="index"
          />
        </template>
        <template #cell(start_date)="data">
          <div class="working__info">
            <div class="working__road flex m-0" v-if="data.item.end_date">
              <p class="working__road-text working__road_begin" :class="{'working__road_begin_generated': dateDiff(data.item.start_date, data.item.end_date) > 80 }">{{ showDate(data.item.start_date) }}</p>
              <div class="working__road-block">
                <div class="working__road-box" :class="{'working__road-generated': dateDiff(data.item.start_date, data.item.end_date) > 80}" :style="dateDiffWidth(data.item.start_date, data.item.end_date)" />
                <div class="working__road-box-after" />
              </div>
              <p class="working__road-text">{{ showDate(data.item.end_date) }}</p>
            </div>
            <div v-else>
              <button class="filter__item-btn" @click="data.item.show_date = !data.item.show_date">
                Укажите дату
              </button>
              <div v-show="data.item.show_date" class="sub-header__calendar">
                <vc-date-picker
                    v-model="data.item.start_date"
                    :model-config="modelConfig"
                    class="add__calendar"
                    :rows="1"
                    mode="dateTime"
                    is24hr
                />
                <button class="btn btn-primary mt-2" @click="startDate(data.item, data.index)">Ok</button>
                <button class="btn btn-primary mt-2" @click="inputDataClean(data.index)">Отмена</button>
              </div>
            </div>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  components: {
    StatisticBlock: () => ({
      component: import("@/components/info/repairStatistics"),
    }),
    RegularBlock: () => ({
      component: import('@/components/info/infoRegularRepair')
    }),

  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      dateShow: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss',
      },
      listStatus: [
        {name: 'Ожидает', index: 'awaiting', disabled: true},
        {name: 'В процессе', index: 'in_work'},
        {name: 'Просрочено', index: 'overdue', disabled: true},
        {name: 'Завершен', index: 'finished'},
      ],
      fields: [
        {
          key: "transport_mark_model",
          label: "Авто",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "transport_number",
          label: "Номер",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "character",
          label: "Тип т",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "name",
          label: "Вид ремонта",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "responsible",
          label: "Ответственный",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "start_date",
          label: "Срок ремонта",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "status",
          label: "Статус",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
      statuses: {
        finished: "table-btn__status",
        awaiting: "table-btn__status-pending",
        in_work: "table-btn__status-active table-btn__status",
        overdue: 'table-btn__status-busy'
      }
    };
  },
  computed: {
    rows() {
      if (this.serviceList) {
        this.$emit("spinner");
        return this.serviceList
      }
      return 3;
    },
    ...mapState(["serviceList", "textFilter", "typeFilter", "statusFilter"]),
  },
  async created() {
    if (this.$route.query.page != undefined && this.$route.query.page != 1) {
      this.currentPage = this.$route.query.page
    }
    await this.search();
  },
  methods: {
    async search(){
      await this.$store.dispatch("getServiceList", {
        page: this.currentPage,
        status: this.statusFilter,
        type_filter: this.typeFilter,
        search: this.textFilter,
      })
    },
    changeStatus(service) {
      this.$api
          .post('/web/service/' + service.id, {status: service.status})
          .then(() => {
            this.$emit('search');
            this.$emit('showModal');
            this.$toast.success("Успешно");
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message);
            this.$emit('showModal');
          });
    },
    startDate(service, index) {
      this.$api
          .post('/web/service/' + service.id, {start_date: service.start_date})
          .then(() => {
            this.rows.data[index].show_date = false;
            this.$toast.success("Успешно");
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message);
            this.$emit('showModal');
          });
    },
    inputDataClean(index) {
      this.rows.data[index].show_date = false;
      this.rows.data[index].start_date = null;
    },
    changePage() {
      const searchURL = new URL(window.location);
      searchURL.searchParams.set('page', this.currentPage);
      window.history.pushState({}, '', searchURL)
    },
    dateDiff(from, to) {

      if (from) {
        const date1 = new Date(from);
        const date2 = new Date();
        const date3 = new Date(to);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffDaysEnd = Math.ceil(Math.abs(date3 - date1) / (1000 * 60 * 60 * 24));

        return diffDays/diffDaysEnd*100 > 95 ? 96 : diffDays/diffDaysEnd*100;
      }

      return 0;
    },
    dateDiffWidth(from, to) {

      var diff = this.dateDiff(from, to)

      return 'width:' + diff+'%;';
    },
    showDate(date){
      if (!date)
        return;

      return new Date(date).toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'})
    },
  },
  watch: {
    async currentPage() {
      await this.search();
    },
    async statusFilter() {
      this.currentPage = 1;
      await this.search();
    },
    async textFilter() {
      this.currentPage = 1;
      await this.search();
    },
    async typeFilter() {
      this.currentPage = 1;
      await this.search();
    },
  },
};
</script>

<style>
.pagination,
.pagination__text {
  position: absolute;
  top: -164px;
  right: 50px;
}
.pagination__text {
  right: 128px;
  top: -154px;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.table {
  width: 100%;
  margin-top: 10px;
}
.product-category {
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  background: #F2F2F8;
}
.working__road-box {
  background: #28A745;
}
.working__road-box::before {
  background: #28A745;
}
.working__road-box::after {
  border-color: #28A745
}
.working__road_begin {
  color: #28A745;
}
.working__road-generated {
  background: #E74C3C;
}
.working__road-generated::after {
  border-color: #E74C3C;
}
.working__road-generated::before {
  background: #E74C3C;
}
.working__road_begin_generated {
  color: #E74C3C;
}
.table-btn__status-pending {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #FFF4D2;
  color: #FF9E0D;
}
.table-btn__status-busy {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #FBD2CE;
  color: #E74C3C;
}
.add__calendar {
  position: static;
  background: #fff;
}
.sub-header__calendar {
  position: absolute;
  z-index: 4;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}
</style>
